import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-form__wrapper" }
const _hoisted_2 = { class: "tabs-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_gui_button = _resolveComponent("gui-button")!
  const _component_form_block = _resolveComponent("form-block")!
  const _component_form_extra = _resolveComponent("form-extra")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_gui_loader, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_gui_button, {
              view: "text",
              label: "Основное",
              color: _ctx.getColorButtonTab('general'),
              type: "button",
              onBtnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeTab = 'general'))
            }, null, 8, ["color"]),
            _createVNode(_component_gui_button, {
              view: "text",
              label: "Доп. информация",
              color: _ctx.getColorButtonTab('optional'),
              type: "button",
              onBtnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeTab = 'optional'))
            }, null, 8, ["color"])
          ]),
          (_ctx.activeTab === 'general')
            ? (_openBlock(), _createBlock(_component_form_block, {
                key: 0,
                "customer-id": _ctx.customerId,
                onReturnBefore: _ctx.returnBefore
              }, null, 8, ["customer-id", "onReturnBefore"]))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'optional')
            ? (_openBlock(), _createBlock(_component_form_extra, {
                key: 1,
                "customer-id": _ctx.customerId,
                onReturnBefore: _ctx.returnBefore
              }, null, 8, ["customer-id", "onReturnBefore"]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}