
import { computed, defineComponent, onBeforeMount, reactive, ref, watch } from 'vue';
import moment from 'moment';
import CustomerEntityModule from '@/store/customers/entity';
import { strings } from '@/lib/stringConst';
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import AppTable from '@/components/ui/table/Table.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import Loading from '@/components/Loading.vue';
import userModule from '@/store/user';
import { GuiButton, GuiCheckbox, GuiTextInput, GuiDatePicker, GuiDropdown } from '@library/gigant_ui';
import { CustomerTextFields, FastReplacementFee, LunchSchemes } from '@/interfaces/models/customer.interface';
import { DropdownItem } from '@library/gigant_ui/dist/types/interfaces/dropdownItem';
import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import validator from '@/lib/validator';
import useVuelidate from '@vuelidate/core';
import FeesHistoryModal from './FeesHistoryModal.vue';
import { formatPhoneWithBrackets, getPlural, minutesToTime } from '@/lib/Utils';
import { useClientServiceType } from './composables/useClientServiceType';
import { useRoute } from 'vue-router';
import { ClientPartners } from '@/interfaces/partner.interface';

interface CustomerState {
  [key: string]: boolean | string;
  isLoading: boolean;
  saveInProgress: boolean;
  customerId: string;
  createdAt: string;
  updatedAt: string;
}

interface CustomerFormParams {
  minutesBreakItems: DropdownItem[];
  isPaid: DropdownItem[];
}

export default defineComponent({
  name: 'formExtra',
  emits: ['returnBefore'],
  components: {
    AppTable,
    GuiButton,
    GuiCheckbox,
    GuiTextInput,
    GuiDatePicker,
    GuiDropdown,
    Loading,
    AppCol,
    AppRow,
    AppFieldset,
    FeesHistoryModal,
  },
  props: {
    customerId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const customerId = route.params.customerId as string;
    const {
      isLoading: isLoadingServiceType,
      table: serviceTypeTable,
      serviceTypeItems,
      clientServiceTypeState,
      createClientServiceType,
      updateClientServiceType,
      deleteClientServiceType,
      init: initClientServiceType,
    } = useClientServiceType(customerId);

    const selectedBreaks = ref([]);
    const currentPage = ref(1);
    const pageAmountItems = ref(50);
    const titles: TableHeaderInterface[] = [
      { id: 'duration', name: 'Продолжительность перерыва', visible: true, align: 'right' },
      { id: 'isPaid', name: 'Оплачивается', visible: true, align: 'right' },
      { id: 'delete', name: '', visible: true, align: 'right' },
    ];

    const state: CustomerState = reactive({
      isLoading: true,
      saveInProgress: false,
      customerId: props.customerId,
      createdAt: '',
      updatedAt: '',
    });
    const customerForm = reactive({
      name: '',
      isActive: false,
      legalName: '',
      externalName: '',
      legalAddress: '',
      actualAddress: '',
      legalForm: '',
      subcontractors: [] as string[],
      contractor: '',
      type: '',
      email: '',
      phone: '',
      fastReplacementFee: '',
      initialFastReplacementFee: '',
      fastReplacementFeeStart: undefined as Date | undefined,
      isTest: false,
      hasCabinet: false,
      autoCheckIn: true,
      autoCheckup: false,
      paymentDivided: false,
      accountManager: '',
      importShiftsSource: '',
      isClientCustomer: 'false',
      inn: '',
      kpp: '',
      ogrn: '',
      bank: '',
      paymentAccount: '',
      ks: '',
      bic: '',
      responsibleName: '',
      responsiblePosition: '',
      responsibleEmail: '',
      responsiblePhone: '',
      minute: 0,
      isPaid: 'false',
      lunchSchemes: [{ duration: 0, isPaid: '0' }],
      breakTable: [{ duration: { minutes: 0 }, isPaid: 'false' }],
      isAddBreak: false,
    });

    const responseErrors: Record<string, string> = reactive({});

    const isOpenHistoryFeeModal = ref(false);
    const minFastReplacementFeeStartDate = moment().startOf('day').toDate();

    let initialFastReplacementFee = ref<number>();
    let initialFastReplacementFeeStart: Date;

    const fastReplacementFeeStartDate = computed(() => {
      const date = customerForm.fastReplacementFeeStart;

      return date ? moment(date as Date).format('YYYY-MM-DD') : '';
    });

    const isChangedFastReplacementFee = computed(
      () => customerForm.fastReplacementFee && Number(customerForm.fastReplacementFee) !== initialFastReplacementFee.value
    );

    const entityModel = computed(() => CustomerEntityModule.model);

    watch(isChangedFastReplacementFee, async (isChangedFee) => {
      if (!isChangedFee && initialFastReplacementFeeStart) {
        customerForm.fastReplacementFeeStart = initialFastReplacementFeeStart;
      }
    });

    watch(
      () => [customerForm.minute],
      () => {
        let minutes = 0;
        if (customerForm.minute > 0) {
          minutes += customerForm.minute;
        }
        if (customerForm.breakTable.length) {
          for (let i = 0; i < customerForm.breakTable.length; i++) {
            if (customerForm.minute == customerForm.breakTable[i].duration.minutes) {
              customerForm.isPaid = customerForm.breakTable[i].isPaid;
              customerForm.isAddBreak = false;
              break;
            } else {
              customerForm.isAddBreak = minutes > 0;
            }
          }
        } else {
          customerForm.isAddBreak = minutes > 0;
        }
      }
    );

    watch(
      () => customerForm.isPaid,
      () => {
        for (let i = 0; i < customerForm.breakTable.length; i++) {
          if (customerForm.minute == customerForm.breakTable[i].duration.minutes) {
            customerForm.breakTable[i].isPaid = customerForm.isPaid;
          }
        }
      }
    );

    const validationRules = computed(() => {
      const localRules: Record<string, unknown> = {
        hours: {
          maxValue: validator({
            name: 'maxValue',
            message: 'Максимальное значение - 23',
            attributes: 23,
          }),
          passInteger: validator({
            name: 'regexp',
            message: 'Допустимы только целые числа',
            attributes: /^\d+$/,
          }),
        },
        fastReplacementFee: {
          passInteger: validator({
            name: 'regexp',
            message: 'Допустимы только целые числа',
            attributes: /^\d+$/,
          }),
          minValue: validator({
            name: 'minValue',
            message: 'Значение не может быть ниже нуля',
            attributes: 0,
          }),
          maxValue: validator({
            name: 'maxValue',
            message: 'Максимальное значение - 2000',
            attributes: 2000,
          }),
        },
        importShiftsSource: {
          maxLength: validator({
            name: 'maxLength',
            message: 'Ограничение по длине не более 512 символов',
            attributes: 512,
          }),
        },
      };

      return localRules;
    });

    const v$ = useVuelidate(validationRules, customerForm);

    const changeLunchInTable = (item: { duration: { minutes: number }; isPaid: string }): boolean => {
      let isChange = false;
      customerForm.lunchSchemes.forEach((itemLunch: any) => {
        if (itemLunch.duration === item.duration.minutes) {
          if (
            (itemLunch.isPaid === '1' && item.isPaid === 'true') ||
            (itemLunch.isPaid === '0' && item.isPaid === 'false')
          ) {
            isChange = false;
          } else {
            isChange = true;
          }
        }
      });

      return isChange;
    };

    const getSuffixNameDay = (day: number): string => {
      return getPlural(day, ['день', 'дня', 'дней']);
    };

    const getSuffixNameHour = (hour: number): string => {
      return getPlural(hour, ['час', 'часа', 'часов']);
    };

    const getTime = (minutes: number): string => {
      return minutesToTime(minutes);
    };

    const interval = 5;
    const minValue = 5;
    const maxValue = 120;

    const generateArrayWithInterval = (interval: number, minValue: number, maxValue: number) => {
      const resultArray = [];

      for (let i = minValue; i <= maxValue; i += interval) {
        const obj = {
          id: i.toString(),
          label: i.toString() + ' минут',
          value: i.toString(),
          icon: '',
          disabled: false,
        };
        resultArray.push(obj);
      }

      return resultArray;
    };

    const stateParams: CustomerFormParams = reactive({
      minutesBreakItems: generateArrayWithInterval(interval, minValue, maxValue),
      isPaid: [
        {
          id: 'paid',
          label: 'Оплачивается',
          value: 'true',
          icon: '',
          disabled: false,
        },
        {
          id: 'unpaid',
          label: 'Не оплачивается',
          value: 'false',
          icon: '',
          disabled: false,
        },
      ],
    });

    onBeforeMount(async () => {
      await initClientServiceType();

      initFormState();

      state.isLoading = false;
    });

    const initFormState = () => {
      if (!props.customerId?.length) {
        return;
      }

      const model = CustomerEntityModule.model;

      customerForm.name = model.name;
      customerForm.isActive = model.isActive;
      customerForm.legalName = model.legalName;
      customerForm.externalName = model.externalName;
      customerForm.legalAddress = model.legalAddress;
      customerForm.actualAddress = model.actualAddress;
      customerForm.legalForm = model.legalForm;
      customerForm.type = model.type;
      customerForm.email = model.email;
      customerForm.phone = formatPhoneWithBrackets(model.phone);
      customerForm.isTest = model.isTest;
      customerForm.hasCabinet = model.hasCabinet;
      customerForm.autoCheckIn = model.autoCheckIn;
      customerForm.autoCheckup = model.autoCheckup;
      customerForm.paymentDivided = model.paymentDivided;
      customerForm.minute = 0;
      customerForm.isPaid = 'false';
      customerForm.isAddBreak = false;
      customerForm.lunchSchemes = [];
      customerForm.breakTable = [];
      customerForm.contractor = model.contractor.uuid;

      if (model.subcontractors) {
        const contractorsUuid = ((model.subcontractors as unknown) as ClientPartners[]).map(
          (item) => item.partner.uuid
        ) as string[];

        customerForm.subcontractors = contractorsUuid;
      }

      model.lunchSchemes.forEach((item) => {
        customerForm.lunchSchemes.push({ duration: item.duration, isPaid: item.isPaid ? '1' : '0' });
      });

      customerForm.lunchSchemes.forEach((item: any) => {
        if (item.duration > 0) {
          const time = {
            minutes: item.duration,
          };
          const breakTime = { duration: time, isPaid: item.isPaid === '1' ? 'true' : 'false' };
          customerForm.breakTable.push(breakTime);
        }
      });

      customerForm.breakTable.sort((a, b) => a.duration.minutes - b.duration.minutes);

      const currentFastReplacementFee = getCurrentFastReplacementFee(model.fastReplacementFees);

      if (currentFastReplacementFee) {
        initialFastReplacementFee.value = currentFastReplacementFee.fee;
        customerForm.fastReplacementFee = currentFastReplacementFee.fee.toString();

        const feeStart = new Date(currentFastReplacementFee.start);
        initialFastReplacementFeeStart = feeStart;
        customerForm.fastReplacementFeeStart = feeStart;
      }

      if (model.accountManager) {
        const accountManagerId = model.accountManager.id.toString();
        customerForm.accountManager = accountManagerId;
      }

      customerForm.importShiftsSource = model.importShiftsSource;
      customerForm.isClientCustomer = String(model.isClientCustomer);
      customerForm.inn = model.inn;
      customerForm.kpp = model.kpp;
      customerForm.ogrn = model.ogrn;
      customerForm.bank = model.bank;
      customerForm.paymentAccount = model.paymentAccount;
      customerForm.ks = model.ks;
      customerForm.bic = model.bic;
      customerForm.responsibleName = model.responsibleName;
      customerForm.responsiblePosition = model.responsiblePosition;
      customerForm.responsibleEmail = model.responsibleEmail;
      customerForm.responsiblePhone = model.responsiblePhone;

      state.createdAt = model.createdAt as string;
      state.updatedAt = model.updatedAt as string;
      state.isLoading = false;
    };

    const deleteBreak = (item: any) => {
      const index = customerForm.breakTable.indexOf(item);
      customerForm.breakTable.splice(index, 1);
    };

    const addBreakTable = async (): Promise<void> => {
      await v$.value.$validate();
      if (v$.value.$error) {
        return;
      }
      customerForm.breakTable.push({
        duration: {
          minutes: Number(customerForm.minute),
        },
        isPaid: customerForm.isPaid,
      });
      customerForm.isAddBreak = false;

      customerForm.breakTable.sort((a, b) => a.duration.minutes - b.duration.minutes);
    };

    const getCurrentFastReplacementFee = (feeHistory: FastReplacementFee[]): FastReplacementFee | null => {
      const todayMoment = moment().startOf('date');

      for (const fee of feeHistory) {
        const isDateSameOrBeforeToday = moment(fee.start).isSameOrBefore(todayMoment);

        if (isDateSameOrBeforeToday) {
          return fee;
        }
      }

      return null;
    };

    const textFields = (): CustomerTextFields => {
      let minutes = 0;

      const tempBreak = [] as LunchSchemes[];

      if (customerForm.breakTable.length) {
        customerForm.breakTable.forEach((item) => {
          minutes = 0;
          if (item.duration.minutes > 0) {
            minutes += item.duration.minutes;
          }

          tempBreak.push({ duration: minutes, isPaid: item.isPaid === 'true' ? '1' : '0' });
        });
      } else {
        customerForm.lunchSchemes = [];
      }

      customerForm.lunchSchemes = tempBreak;

      const fields: CustomerTextFields = {
        name: customerForm.name,
        isActive: customerForm.isActive ? '1' : '0',
        legalName: customerForm.legalName,
        externalName: customerForm.externalName,
        legalAddress: customerForm.legalAddress,
        actualAddress: customerForm.actualAddress,
        legalForm: customerForm.legalForm,
        contractor: customerForm.contractor,
        type: customerForm.type,
        email: customerForm.email,
        phone: customerForm.phone,
        isTest: customerForm.isTest ? '1' : '0',
        hasCabinet: customerForm.hasCabinet ? '1' : '0',
        autoCheckIn: customerForm.autoCheckIn || customerForm.autoCheckup ? '1' : '0',
        autoCheckup: customerForm.autoCheckup ? '1' : '0',
        paymentDivided: customerForm.paymentDivided ? '1' : '0',
        accountManager: customerForm.accountManager.length ? customerForm.accountManager : '0',
        importShiftsSource: customerForm.importShiftsSource?.trim(),
        isClientCustomer: customerForm.isClientCustomer == 'true' ? '1' : '0',
        inn: customerForm.inn,
        kpp: customerForm.kpp,
        ogrn: customerForm.ogrn,
        bank: customerForm.bank,
        paymentAccount: customerForm.paymentAccount,
        ks: customerForm.ks,
        bic: customerForm.bic,
        responsibleName: customerForm.responsibleName,
        responsiblePosition: customerForm.responsiblePosition,
        responsibleEmail: customerForm.responsibleEmail,
        responsiblePhone: customerForm.responsiblePhone,
        lunchSchemes: customerForm.lunchSchemes,
      };
      fields.clientSubcontractors = customerForm.subcontractors.map((item) => ({ partner: item }));

      if (isChangedFastReplacementFee.value) {
        fields.fastReplacementFee = customerForm.fastReplacementFee;
        fields.fastReplacementFeeStart = fastReplacementFeeStartDate.value;
      }

      return fields;
    };

    const returnBefore = (): void => {
      emit('returnBefore');
    };

    const clearResponseErrors = (specificField?: string) => {
      if (specificField && responseErrors[specificField]) {
        delete responseErrors[specificField];

        return;
      }

      Object.keys(responseErrors).forEach((key) => delete responseErrors[key]);
    };

    const edit = async (): Promise<Record<string, string> | undefined> => {
      const errors = await CustomerEntityModule.saveUpdate({
        customerId: props.customerId as string,
        textFieldsData: textFields(),
      });

      if (!errors) {
        initFormState();
      }

      return errors;
    };

    const save = async (): Promise<void> => {
      await v$.value.$validate();
      if (v$.value.$error) {
        return;
      }

      try {
        state.saveInProgress = true;
        clearResponseErrors();

        const errors = await edit();

        if (errors) {
          Object.assign(responseErrors, errors);
        }
      } finally {
        state.saveInProgress = false;
      }
    };

    const canEditCustomer = (): boolean => {
      return userModule.userHasPermission('CAN_EDIT_CLIENT');
    };

    return {
      isLoadingServiceType,
      serviceTypeTable,
      serviceTypeItems,
      clientServiceTypeState,
      createClientServiceType,
      updateClientServiceType,
      deleteClientServiceType,

      customerForm,
      state,
      stateParams,
      minFastReplacementFeeStartDate,
      fastReplacementFeeStartDate,
      isChangedFastReplacementFee,
      isOpenHistoryFeeModal,
      entityModel,
      responseErrors,

      text: strings,

      edit,
      save,
      canEditCustomer,
      returnBefore,
      clearResponseErrors,
      deleteBreak,
      addBreakTable,
      getSuffixNameDay,
      getSuffixNameHour,
      getTime,
      changeLunchInTable,
      selectedBreaks,
      titles,
      currentPage,
      pageAmountItems,

      v$,
    };
  },
});
