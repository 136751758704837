import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, vShow as _vShow, toDisplayString as _toDisplayString, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e846c7d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"position":"relative"}
}
const _hoisted_2 = { style: {"padding":"12px 8px 0 8px"} }
const _hoisted_3 = { style: {"padding":"12px 8px 10px 8px"} }
const _hoisted_4 = { style: {"padding":"12px 8px 10px 8px"} }
const _hoisted_5 = { class: "cp-text-overflow cp-title-page" }
const _hoisted_6 = {
  key: 0,
  class: "gui-label-error-hint__hint-text gui-label-error-hint__hint_error",
  style: {"text-align":"justify"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_gui_text_input = _resolveComponent("gui-text-input")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_date_picker = _resolveComponent("gui-date-picker")!
  const _component_fees_history_modal = _resolveComponent("fees-history-modal")!
  const _component_app_row = _resolveComponent("app-row")!
  const _component_gui_checkbox = _resolveComponent("gui-checkbox")!
  const _component_app_fieldset = _resolveComponent("app-fieldset")!
  const _component_gui_dropdown = _resolveComponent("gui-dropdown")!
  const _component_gui_button = _resolveComponent("gui-button")!
  const _component_app_table = _resolveComponent("app-table")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.state.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_loading, { "stroke-color": "#6E258B" })
      ]))
    : (_openBlock(), _createElementBlock("form", {
        key: 1,
        ref: "form",
        class: "customer-extra-form",
        novalidate: "",
        onSubmit: _cache[13] || (_cache[13] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
      }, [
        _createVNode(_component_app_row, {
          gap: ['0', '8'],
          class: "cp-form-input-block"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_app_row, {
                  class: "cp-height--full",
                  direction: "column"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_col, { grow: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_app_fieldset, { class: "cp-margin__t--8 cp-height--full" }, {
                          title: _withCtx(() => [
                            _createTextVNode("Дополнительная информация")
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_component_app_row, { gap: ['0', '8'] }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_app_col, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_gui_text_input, {
                                        modelValue: _ctx.customerForm.fastReplacementFee,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customerForm.fastReplacementFee) = $event)),
                                        label: "Ставка срочной замены",
                                        class: "cp-width--full",
                                        view: "outlined",
                                        error: _ctx.v$.fastReplacementFee.$error ? _ctx.v$.fastReplacementFee.$errors[0].$message : ''
                                      }, null, 8, ["modelValue", "error"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_app_col, null, {
                                    default: _withCtx(() => [
                                      (_openBlock(), _createBlock(_component_gui_date_picker, {
                                        modelValue: _ctx.customerForm.fastReplacementFeeStart,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customerForm.fastReplacementFeeStart) = $event)),
                                        key: _ctx.fastReplacementFeeStartDate,
                                        disabled: !_ctx.isChangedFastReplacementFee,
                                        "min-date": _ctx.minFastReplacementFeeStartDate,
                                        label: "Дата начала действия ставки",
                                        placeholder: "Выберите дату",
                                        class: "cp-width--full",
                                        view: "outlined",
                                        "scroll-container": "id-default-layout-content",
                                        error: _ctx.responseErrors.fastReplacementFeeStart,
                                        top: "",
                                        onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clearResponseErrors('fastReplacementFeeStart')))
                                      }, null, 8, ["modelValue", "disabled", "min-date", "error"])),
                                      (_ctx.entityModel.fastReplacementFees.length)
                                        ? _withDirectives((_openBlock(), _createElementBlock("button", {
                                            key: 0,
                                            class: "fee-history-button icon-logs",
                                            type: "button",
                                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isOpenHistoryFeeModal = true))
                                          }, null, 512)), [
                                            [_directive_tooltip, 'История ставок']
                                          ])
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_fees_history_modal, {
                                        open: _ctx.isOpenHistoryFeeModal,
                                        "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isOpenHistoryFeeModal) = $event)),
                                        items: _ctx.entityModel.fastReplacementFees
                                      }, null, 8, ["open", "items"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_app_row, { gap: ['0', '8'] }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_app_col, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_gui_checkbox, {
                                        modelValue: _ctx.customerForm.paymentDivided,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customerForm.paymentDivided) = $event)),
                                        label: "Разделить начисления"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_app_col, {
                      grow: 0,
                      class: "cp-padding__t-22"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_app_fieldset, null, {
                          title: _withCtx(() => [
                            _createTextVNode("Ротация по типу услуги")
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createVNode(_component_app_row, { gap: ['0', '8'] }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_app_col, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_gui_dropdown, {
                                        class: "cp-width--full",
                                        modelValue: _ctx.clientServiceTypeState.serviceType,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.clientServiceTypeState.serviceType) = $event)),
                                        items: _ctx.serviceTypeItems,
                                        disabled: _ctx.isLoadingServiceType,
                                        label: "Тип услуги",
                                        view: "outlined",
                                        "scroll-container": "id-default-layout-content",
                                        "hard-select": "",
                                        "is-search-enabled": ""
                                      }, null, 8, ["modelValue", "items", "disabled"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_app_col, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_gui_checkbox, {
                                        modelValue: _ctx.clientServiceTypeState.rotation,
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.clientServiceTypeState.rotation) = $event)),
                                        label: "Ротация включена",
                                        disabled: _ctx.isLoadingServiceType,
                                        class: "cp-padding__t-16 cp-margin__t--2"
                                      }, null, 8, ["modelValue", "disabled"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_app_row, {
                                gap: ['0', '8'],
                                class: "cp-padding__t-4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_app_col, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_gui_button, {
                                        type: "button",
                                        view: "outlined",
                                        label: _ctx.text.btnAdd,
                                        disabled: _ctx.isLoadingServiceType || !_ctx.clientServiceTypeState.serviceType,
                                        onBtnClick: _ctx.createClientServiceType
                                      }, null, 8, ["label", "disabled", "onBtnClick"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_app_col, { grow: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_app_table, {
                          headers: _ctx.serviceTypeTable.titles,
                          items: _ctx.serviceTypeTable.rows,
                          defaultFooter: false,
                          class: "customer-extra-form__break-table"
                        }, {
                          "item-rotation": _withCtx(({ value, item }) => [
                            _createVNode(_component_gui_checkbox, {
                              "model-value": value,
                              disabled: _ctx.isLoadingServiceType,
                              "onUpdate:modelValue": ($event: any) => (_ctx.updateClientServiceType(item.id, !value))
                            }, null, 8, ["model-value", "disabled", "onUpdate:modelValue"])
                          ]),
                          "item-actions": _withCtx(({ item }) => [
                            _createVNode(_component_gui_button, {
                              label: "x",
                              view: "outlined",
                              type: "button",
                              onBtnClick: ($event: any) => (_ctx.deleteClientServiceType(item.id))
                            }, null, 8, ["onBtnClick"])
                          ]),
                          _: 1
                        }, 8, ["headers", "items"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_app_col, { class: "customer-extra-form__break-column" }, {
              default: _withCtx(() => [
                _createVNode(_component_app_row, { direction: "column" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_col, { grow: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_app_fieldset, null, {
                          title: _withCtx(() => [
                            _createTextVNode("Добавление перерыва")
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_4, [
                              _createVNode(_component_app_row, { gap: ['0', '8'] }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_app_col, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_gui_dropdown, {
                                        class: "cp-width--full",
                                        modelValue: _ctx.customerForm.minute,
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.customerForm.minute) = $event)),
                                        items: _ctx.stateParams.minutesBreakItems,
                                        hardSelect: true,
                                        label: "Время перерыва",
                                        view: "outlined",
                                        "scroll-container": "id-default-layout-content"
                                      }, null, 8, ["modelValue", "items"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_app_col, null, {
                                    default: _withCtx(() => [
                                      _withDirectives(_createVNode(_component_gui_dropdown, {
                                        isClearable: false,
                                        class: "cp-width--full",
                                        modelValue: _ctx.customerForm.isPaid,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.customerForm.isPaid) = $event)),
                                        items: _ctx.stateParams.isPaid,
                                        hardSelect: true,
                                        view: "outlined",
                                        label: "Тип перерыва",
                                        "scroll-container": "id-default-layout-content"
                                      }, null, 8, ["modelValue", "items"]), [
                                        [_vShow, _ctx.customerForm.isAddBreak]
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_app_row, {
                                gap: ['0', '8'],
                                class: "cp-padding__t-4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_app_col, null, {
                                    default: _withCtx(() => [
                                      (_ctx.customerForm.isAddBreak)
                                        ? (_openBlock(), _createBlock(_component_gui_button, {
                                            key: 0,
                                            label: _ctx.text.btnAdd,
                                            onBtnClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.addBreakTable())),
                                            type: 'submit',
                                            view: "outlined"
                                          }, null, 8, ["label"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.customerForm.breakTable.length > 0)
                  ? (_openBlock(), _createBlock(_component_app_table, {
                      key: 0,
                      headers: _ctx.titles,
                      items: _ctx.customerForm.breakTable,
                      defaultFooter: false,
                      page: _ctx.currentPage,
                      totalPages: Math.ceil(_ctx.customerForm.breakTable.length / _ctx.pageAmountItems),
                      totalItems: _ctx.customerForm.breakTable.length,
                      "page-items": _ctx.pageAmountItems,
                      class: "customer-extra-form__break-table",
                      onChangePage: _cache[11] || (_cache[11] = ($event: any) => (_ctx.currentPage = $event)),
                      onSelectAmount: _cache[12] || (_cache[12] = ($event: any) => (_ctx.pageAmountItems = $event))
                    }, {
                      "item-duration": _withCtx(({ item }) => [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getTime(item.duration.minutes)), 1)
                      ]),
                      "item-isPaid": _withCtx(({ item }) => [
                        _createVNode(_component_gui_dropdown, {
                          class: "cp-width--full",
                          modelValue: item.isPaid,
                          "onUpdate:modelValue": ($event: any) => ((item.isPaid) = $event),
                          items: _ctx.stateParams.isPaid,
                          isClearable: false,
                          view: "outlined",
                          required: "",
                          hardSelect: true
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "items"]),
                        (_ctx.changeLunchInTable(item))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_6, " Изменение может повлиять на будущие и несверенные заказы "))
                          : _createCommentVNode("", true)
                      ]),
                      "item-delete": _withCtx(({ item }) => [
                        _createVNode(_component_gui_button, {
                          label: "x",
                          view: "outlined",
                          onBtnClick: ($event: any) => (_ctx.deleteBreak(item)),
                          type: 'button'
                        }, null, 8, ["onBtnClick"])
                      ]),
                      _: 1
                    }, 8, ["headers", "items", "page", "totalPages", "totalItems", "page-items"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.canEditCustomer())
          ? (_openBlock(), _createBlock(_component_app_row, {
              key: 0,
              class: "cp-padding__t-22 cp-padding__b--30 cp-padding__l--8",
              "justify-content": "start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_col, {
                  class: "cp-padding__r-12",
                  grow: 0
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_gui_button, {
                      label: _ctx.text.btnSave,
                      disabled: _ctx.state.saveInProgress,
                      type: 'submit'
                    }, null, 8, ["label", "disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_app_col, { grow: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_gui_button, {
                      label: _ctx.text.btnCancel,
                      view: "outlined",
                      onBtnClick: _ctx.returnBefore
                    }, null, 8, ["label", "onBtnClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 544))
}