import { computed, reactive, ref } from 'vue';
import { createServiceType, deleteServiceType, getServiceTypeList, updateServiceType } from '@/api/client';
import { getServiceTypeAll } from '@/api/templates';
import { DropdownItem } from '@library/gigant_ui/dist/types/interfaces/dropdownItem';
import { TableRow, TableTitle } from '@/lib/layouts/page/table.interface';

export interface ClientServiceType {
  id: number;
  serviceTypeId: number;
  serviceTypeName: string;
  rotation: boolean;
}

export function useClientServiceType(clientId: string) {
  const defaultState = {
    serviceType: '',
    rotation: false,
  };

  const isLoading = ref(false);
  const serviceTypeItems = ref<DropdownItem[]>([]);
  const table = reactive({
    titles: [] as TableTitle[],
    rows: [] as TableRow<ClientServiceType>[],
  });

  const clientServiceTypeState = reactive({ ...defaultState });

  const clientServiceTypeNames = computed(() => table.rows.map(({ serviceTypeName }) => serviceTypeName));

  const filteredServiceTypeItems = computed(() =>
    serviceTypeItems.value.filter(({ label }) => !clientServiceTypeNames.value.includes(label))
  );

  async function getTable() {
    const { titles, rows } = await getServiceTypeList(clientId);

    for (const title of titles) {
      if (title.id === 'rotation') {
        title.align = 'center';
      } else if (['id', 'serviceTypeId'].includes(title.id)) {
        title.visible = false;
      }
    }

    titles.push({ id: 'actions', visible: true, align: 'right' });

    table.titles = titles;
    table.rows = rows;
  }

  async function createClientServiceType() {
    isLoading.value = true;

    try {
      await createServiceType(clientId, {
        serviceType: clientServiceTypeState.serviceType,
        rotation: clientServiceTypeState.rotation ? '1' : '0',
      });

      Object.assign(clientServiceTypeState, defaultState);

      await getTable();
    } finally {
      isLoading.value = false;
    }
  }

  async function deleteClientServiceType(clientServiceTypeId: string) {
    isLoading.value = true;

    try {
      await deleteServiceType(clientId, clientServiceTypeId);
      await getTable();
    } finally {
      isLoading.value = false;
    }
  }

  async function updateClientServiceType(clientServiceTypeId: string, rotation: boolean) {
    isLoading.value = true;

    try {
      await updateServiceType(clientId, clientServiceTypeId, rotation ? '1' : '0');
      await getTable();
    } finally {
      isLoading.value = false;
    }
  }

  async function getServiceTypeItems() {
    const serviceTypes = await getServiceTypeAll();

    serviceTypeItems.value = serviceTypes.map(({ id, name }) => ({
      id: id.toString(),
      label: name,
      value: id.toString(),
      icon: '',
      disabled: false,
    }));
  }

  async function init() {
    isLoading.value = true;

    try {
      await getServiceTypeItems();
      await getTable();
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    serviceTypeItems: filteredServiceTypeItems,
    clientServiceTypeState,
    table,
    init,
    createClientServiceType,
    updateClientServiceType,
    deleteClientServiceType,
  };
}
