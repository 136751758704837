
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './../parts/_form.vue';
import formExtra from '@/views/customers/parts/formExtra.vue';

import CustomerEntityModule from '@/store/customers/entity';
import { GuiButton, GuiLoader } from '@library/gigant_ui';

type Tab = 'general' | 'optional';

@Component({
  components: {
    GuiLoader,
    GuiButton,
    FormBlock,
    formExtra,
  },
  async beforeMount() {
    this.customerId = this.$route.params.customerId as string;
    await CustomerEntityModule.initEdit(this.customerId);
  },
})
export default class CustomerProfile extends Form {
  customerId = '';

  activeTab: Tab = 'general';

  get isLoading(): boolean {
    return CustomerEntityModule.isLoading;
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers' });
  }

  async mounted(): Promise<void> {
    CustomerEntityModule.initTabsItems();
  }

  getColorButtonTab(tab: Tab): string {
    return tab === this.activeTab ? 'accent' : 'primary';
  }
}
