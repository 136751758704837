import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "cp-main-inner__wrapper" }
const _hoisted_2 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_3 = { class: "cp-badges-container" }
const _hoisted_4 = {
  key: 0,
  class: "cp-badge cp-badge_test"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_title_return = _resolveComponent("title-return")!
  const _component_profile = _resolveComponent("profile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tabs_nav, {
        items: _ctx.tabsNav,
        "active-id": _ctx.currentTab,
        params: { customerId: _ctx.customerId }
      }, null, 8, ["items", "active-id", "params"]),
      _createVNode(_component_title_return, {
        icon: "clients",
        "icon-size": "19",
        onClick: _ctx.returnBefore
      }, {
        "icon-badge": _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isTest)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Test"))
              : _createCommentVNode("", true)
          ])
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.titlePage), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_profile)
  ]))
}