
import { Component, Vue } from '@/lib/decorator';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import Profile from './profile/Index.vue';

import CustomerEntityModule from '@/store/customers/entity';
import CustomersModule from '@/store/customers';
import { TabsNavInterface } from '@/interfaces/tabs.interface';

@Component({
  components: {
    TitleReturn,
    TabsNav,
    Profile,
  },
  beforeMount() {
    this.customerId = this.$route.params.customerId;
  },
})
export default class CustomerEntityMain extends Vue {
  customerId = '';

  get titlePage(): string {
    return CustomersModule.pageSettings.titleEdit;
  }

  get tabsNav(): TabsNavInterface {
    return CustomerEntityModule.tabsNav;
  }

  get currentTab(): string {
    return CustomerEntityModule.allTabs.profile?.id;
  }

  get isTest(): boolean {
    return CustomerEntityModule.model.isTest;
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers' });
  }
}
